import { Outlet } from 'react-router-dom';
import { Header } from './components/Header';
import { Footer } from './components/Footer';

export const InternalLayout: React.FC = () => {
  return (
    <div className="flex min-h-screen flex-col font-sans">
      <Header />
      <Outlet />
      <Footer />
    </div>
  );
};
