import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';
import { InternalLayout } from './layouts/Internal';
import { DashboardPage } from './pages/DashboardPage';

const lightTheme = createTheme({
  palette: {
    mode: 'light',
  },
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<InternalLayout />}>
      <Route path="/" element={<DashboardPage />} />
    </Route>,
  ),
);

export const App: React.FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={lightTheme}>
        <CssBaseline /> <RouterProvider router={router} />
      </ThemeProvider>
    </QueryClientProvider>
  );
};
