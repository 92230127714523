import { useSupplyTypes } from 'src/hooks/queries/useSupplyTypes';

export const DashboardPage: React.FC = () => {
  const { data: supplyTypes } = useSupplyTypes();
  return (
    <div className="p-4 text-blue-800">
      <h3 className="text-lg font-bold">Tipos de insumo</h3>
      <ul className="list-disc p-4">
        {supplyTypes?.map((type, i) => <li key={i}>{type.name}</li>)}
      </ul>
    </div>
  );
};
