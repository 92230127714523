import cn from 'classnames';

type Props = {
  className?: string;
};

export const Header: React.FC<Props> = ({ className }) => {
  return (
    <header
      className={cn(
        className,
        'flex h-20 w-full items-center justify-between border-b border-gray-800/50 px-4',
      )}
    >
      <h1 className="flex items-center gap-2 text-2xl font-semibold text-blue-900">
        Todos Ganham
      </h1>
    </header>
  );
};
