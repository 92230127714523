import { useQuery } from '@tanstack/react-query';

type SupplyType = {
  name: string;
};

export const useSupplyTypes = () => {
  return useQuery<SupplyType[]>({
    queryKey: ['supply-types'],
    queryFn: () => fetchSupplyTypes(),
  });
};

export const fetchSupplyTypes = async (): Promise<SupplyType[]> => {
  const res = await fetch(
    `${process.env.REACT_APP_PUBLIC_API_BASE_URL}/supply-types`,
  );
  return await res.json();
};
